import React, {useState, useEffect, useCallback} from 'react'

import {ACTION} from 'bl/ns/process/process'

// Components
import Page            from 'cli/ui/pages/layout/Page'
import {Row, Col}      from 'reactstrap'
import {LoadingBig}    from 'cli/ui/components/loading'
import { IBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import ProcessesFilter from 'cli/ui/fragments/process/filter/ProcessesFilter'
import IncomeList      from './IncomeList'
import { NiceTotals } from 'cli/ui/components/nice_totals'
import { COLOR_GROUPS } from 'bl/ns/process/colors'
import withContext from 'cli/context/withContext'



const Income = ({fetcher}) => {
  const [processes, setProcesses]= useState([])
  const [filterMode, setFilterMode]= useState('filter')
  const [dataLoaded, setDataLoaded]= useState(true)
  const [dataEmpty, setDataEmpty]= useState(false)
    const [totals, setTotals]= useState(undefined)
  const [stats, setStats]= useState({
    count: 0, 
    totalFees: 0.0, 
    totalInterests: 0.0,
    total20: 0.0,
    total19: 0.0
  })

  const fetchIncome = useCallback((filter) => {
    const _fetchIncome = async () => {      
      setDataLoaded(false)
      setDataEmpty(false)
      
      // Fetch them
      const res = await fetcher.get('/api/process/read_income', filter)
      const nProcesses = res.data
      setProcesses(nProcesses)
      setDataLoaded(true)
      if (nProcesses.length==0) {
        setDataEmpty(true)
      }
    }

    _fetchIncome()

  }, [fetcher])


  const handleSearchIncome = useCallback((filter) => {
    setFilterMode('resume')
    fetchIncome(filter)
  }, [fetchIncome])  

  
  const handleProcessChange = async (pid, changes, confirm) => {
    let nProcesses= [...processes]
    const procIdx= nProcesses.findIndex((p) => p.id==pid)

    nProcesses[procIdx]= {
      ...nProcesses[procIdx],
      ...changes
    }
    setProcesses(nProcesses)

    if (confirm) {
      await fetcher.post('/api/process/upsave_proc', {id: pid, changes: changes, actionName: ACTION.PAYMENT_DATA})
    }
  }


  useEffect(() => {
    const nStats= {
      count: 0, 
      totalFees: 0.0, 
      totalInterests: 0.0,
      total20: 0.0,
      total19: 0.0      
    }
    processes.map((p) => {
      nStats.count+= 1
      nStats.totalFees+= p.amounts.fees
      nStats.totalInterests+= p.amounts.interests
      nStats.total20+= p.amounts.penalty_20
      nStats.total19+= p.amounts.penalty_19
    })
    setStats(nStats)
  }, [processes])

  useEffect(() => {
    setTotals([
      {price: false, label: 'Expedientes'  , value: stats.count,          color: 'blue'   },
      {price: true , label: 'Cuotas'       , value: stats.totalFees,      color: COLOR_GROUPS['inspect']    },
      {price: true , label: 'Intereses'    , value: stats.totalInterests, color: COLOR_GROUPS['inspect']    },
      {price: true , label: 'Sanción 203'  , value: stats.total20,        color: COLOR_GROUPS['penalty_20'] },
      {price: true , label: 'Sanción 191/2', value: stats.total19,        color: COLOR_GROUPS['penalty_19'] },
    ])
  }, [stats])


  return (
    <Page breads = {[['/', 'Inicio'], ['Recaudación']]}
          title  = {`Recaudación`}
          actions= {null}>
      <Row>
        <Col xs="12">
            <ProcessesFilter filterMode  = {filterMode}
                             onToggleMode= {(mode) => setFilterMode(mode)}
                             onFilter    = {(filter) => handleSearchIncome(filter)}
                             defaultFilters = {{invoicable: 'true', pay_pending: 'true'}}/>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
             {!dataLoaded
              ? <LoadingBig/>  

              : processes.length>0
                ? <>
                    {totals!=undefined
                     ? <NiceTotals totals={totals}/>
                     : null
                    }
                    <IncomeList 
                      processes = {processes}
                      onProcessChange = {(pid, changes, confirm) => handleProcessChange(pid, changes, confirm)}/>
                  </>
                : dataEmpty
                  ? <IBox>
                      <IBoxRow center>
                        <IBoxIconedText icon="empty" text="No hay expedientes con los parámetros buscados"/>
                      </IBoxRow>
                    </IBox>
                  : null
            }
          
        </Col>
      </Row>

    </Page>
  )
}



export default withContext(Income)
