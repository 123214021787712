import React from 'react'
import {intre_from_str, intre_to_str} from 'intre'
import {Row, Col}    from 'reactstrap'
import {FForm, FInputText, FInputFloat, FInputSelect, FInputSelectSearch, FInputDate, FInputSelectMultiple} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'
import { FLOW_STEPS, PENALTY_19_STEPS, PENALTY_20_STEPS, getStepName } from 'bl/ns/process/steps'
import { PENALTY_TYPE, PENALTY_TYPE_NAMES }  from 'bl/ns/process/penalty'

import { TAX_NAMES } from 'bl/ns/taxes'

const FLOW_STEP_IGNORE= [
  FLOW_STEPS.FIRST_DLG ,
  FLOW_STEPS.SECOND_DLG,
  FLOW_STEPS.THIRD_DLG
]

let FLOW_STEP_NAMES= [
  [-1, 'Cerrado']
]
Object.values(FLOW_STEPS)
  .filter(v => FLOW_STEP_IGNORE.indexOf(v)<0)
  .map((v) => {
    const name= getStepName(v, {})
    FLOW_STEP_NAMES.push([v, name])
  })
let PENALTY_19_STEP_NAMES= []
Object.values(PENALTY_19_STEPS).map((v) => {
  const name= getStepName(v, {})
  PENALTY_19_STEP_NAMES.push([v, name])
})
let PENALTY_20_STEP_NAMES= []
Object.values(PENALTY_20_STEPS).map((v) => {
  const name= getStepName(v, {})
  PENALTY_20_STEP_NAMES.push([v, name])
})

const ProcessesFilterForm = ({filter, filterData, onChange, onCancel, onSave, hiddenFields}) => { 

  return (
    <FForm onCancel = {onCancel}
           onSave   = {onSave}
           labels   = {['Cancelar', 'Filtrar']}
          >
            <Row>
              <Col xs="12" sm="6" lg="4">
                {hiddenFields.indexOf('tax_code')<0
                 ? 
                  <FInputSelect
                              options     = {TAX_NAMES}
                              name        = 'tax_code'
                              label       = 'Tributo'
                              icon        = {<Icon icon={"puzzle"}/>}
                              value       = {filter.tax_code}
                              onChange    = {(v) => onChange('tax_code', v)}
                              clearable   = {true}
                              showValidity= {0}
                  />  
                 : null}

                <FInputSelectSearch  
                            options     = {filterData.councils}
                            name        = 'council_id'
                            label       = 'Ayuntamiento'
                            icon        = {<Icon icon={"council"}/>}
                            value       = {filter.council_id}
                            onChange    = {(v) => onChange('council_id', v)}
                            clearable   = {true}
                            searchable  = {true}
                            showValidity= {0}
                />
                <FInputText name        = "taxpayer_name"
                            label       = "Nombre del contribuyente"
                            icon        = {<Icon icon={"taxpayer"}/>}
                            value       = {filter.taxpayer_name}
                            onChange    = {(v) => onChange('taxpayer_name', v)}
                            clearable   = {true}
                            showValidity= {0}
                />
                <FInputText name        = "taxpayer_nif"
                            label       = "NIF del contribuyente"
                            icon        = {<Icon icon={"profile"}/>}
                            value       = {filter.taxpayer_nif}
                            onChange    = {(v) => onChange('taxpayer_nif', v)}
                            clearable   = {true}
                            showValidity= {0}
                />
              </Col>
              <Col xs="12" sm="6" lg="4">
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <FInputDate name        = "start_date_from"
                              label       = "Fecha de inicio desde"
                              value      = {intre_to_str(filter.start_date_from)}
                              onChange   = {(v) => onChange('start_date_from', intre_from_str(v))}
                              showValidity= {0}
                              locale      = 'es'
                  />
                  <FInputDate name        = "start_date_to"
                              label       = "Fecha de inicio hasta"
                              value      = {intre_to_str(filter.start_date_to)}
                              onChange   = {(v) => onChange('start_date_to', intre_from_str(v))}
                              showValidity= {0}
                              locale      = 'es'
                  />
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <FInputDate name        = "end_date_from"
                              label       = "Fecha de fin desde"
                              value      = {intre_to_str(filter.end_date_from)}
                              onChange   = {(v) => onChange('end_date_from', intre_from_str(v))}
                              showValidity= {0}
                              locale      = 'es'
                  />
                  <FInputDate name        = "end_date_to"
                              label       = "Fecha de fin hasta"
                              value      = {intre_to_str(filter.end_date_to)}
                              onChange   = {(v) => onChange('end_date_to', intre_from_str(v))}
                              showValidity= {0}
                              locale      = 'es'
                  />
                </div>

                <FInputFloat name       = "taxes_sum_from"
                            label       = "Más de estas tasas"
                            icon        = {<Icon icon={"dollar"}/>}
                            value       = {filter.taxes_sum_from}
                            decimalSign = {","}
                            onChange    = {(v) => onChange('taxes_sum_from', v)}
                            clearable   = {true}
                            showValidity= {0}
                />

                <FInputFloat name      =  "taxes_sum_to"
                            label       = "Menos de estas tasas"
                            icon        = {<Icon icon={"dollar"}/>}
                            value       = {filter.taxes_sum_to}
                            decimalSign = {","}
                            onChange    = {(v) => onChange('taxes_sum_to', v)}
                            clearable   = {true}
                            showValidity= {0}
                />

                <FInputSelect
                            options     = {[['',''], ['true', 'Sí'], ['false', 'No']]}
                            name        = 'pay_pending'
                            label       = 'Pendiente de pago'
                            icon        = {<Icon icon={"dollar"}/>}
                            value       = {filter.pay_pending}
                            onChange    = {(v) => onChange('pay_pending', v)}
                            clearable   = {true}
                            showValidity= {0}
                />                
              </Col>
              <Col xs="12" sm="6" lg="4">


              <FInputSelectMultiple
                            options     = {FLOW_STEP_NAMES}
                            name        = 'status_step'
                            label       = 'Estado del expediente'
                            icon        = {<Icon icon={"tree"}/>}
                            value       = {filter.status_step}
                            onChange    = {(v) => onChange('status_step', v)}
                            clearable   = {true}
                            showValidity= {0}
                />
                <FInputSelect
                            options     = {PENALTY_TYPE_NAMES}
                            name        = 'penalty_type'
                            label       = 'Tipo Sanción'
                            icon        = {<Icon icon={"puzzle"}/>}
                            value       = {filter.penalty_type}
                            onChange    = {(v) => onChange('penalty_type', v)}
                            clearable   = {true}
                            showValidity= {0}
                />
                {filter.penalty_type == PENALTY_TYPE.ART_203
                 ? 
                  <FInputSelect
                              options     = {PENALTY_20_STEP_NAMES}
                              name        = 'penalty_20_step'
                              label       = 'Estado del Sancionador 203'
                              icon        = {<Icon icon={"tree"}/>}
                              value       = {filter.penalty_20_step}
                              onChange    = {(v) => onChange('penalty_20_step', v)}
                              clearable   = {true}
                              showValidity= {0}
                  />
                 : null}
                {(filter.penalty_type == PENALTY_TYPE.ART_191) || (filter.penalty_type == PENALTY_TYPE.ART_192)
                  ? 
                   <FInputSelect
                               options     = {PENALTY_19_STEP_NAMES}
                               name        = 'penalty_19_step'
                               label       = 'Estado del Sancionador 191/192'
                               icon        = {<Icon icon={"tree"}/>}
                               value       = {filter.penalty_19_step}
                               onChange    = {(v) => onChange('penalty_19_step', v)}
                               clearable   = {true}
                               showValidity= {0}
                   />
                  : null}

              </Col>              
            </Row>
    </FForm>
  )
}

export default ProcessesFilterForm




