import React, { useState } from 'react'
import {ActionPayed} from 'cli/ui/components/buttons/actions'
import IncomePaidModal from './IncomePaidModal'


import PaidProgress from 'cli/ui/fragments/process/fields/progress/PaidProgress'
import withContext from 'cli/context/withContext'

const IncomePaid = ({process, onChangeProcess, permiss}) => {
  const [showModal, setShowModal]= useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }
  
  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "end"}}>
      <div style={{paddingRight: "1em"}}>
        <PaidProgress paid={process.paid_amount || 0.0} to_be_paid={process.to_be_paid}/>
      </div>
      <ActionPayed 
        onClick  = {() => setShowModal(true)}
        enabled  = {permiss.can_user_edit_income()}
        label    = ""
        checked  = {process.is_paid}/>
      
      {permiss.can_user_edit_income()
       ? 
        <IncomePaidModal
          show     = {showModal}
          title    = {`Añadir pagos al expediente ${process.name}`}
          process  = {process}
          onChange = {(changes) => onChangeProcess(changes)}
          onClose  = {() => handleCloseModal()}
          onSave   = {undefined}/>
       : null}
    </div>
  )
}

export default withContext(IncomePaid)