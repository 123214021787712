'use strict';

import {uvl} from 'farrapa/commons'
import {memoize} from 'farrapa/memoize'
import {collSort} from 'farrapa/collections'

import {intre_add_days, intre_add_months} from 'intre'

import {PTYPE} from 'bl/ns/process/process'
import {FLOW_STEPS, PENALTY_20_STEPS} from 'bl/ns/process/steps'
import { GENRE_MALE } from 'bl/ns/genres'
import { SPANISH_PROVINCES } from 'bl/ns/geo'
import { SUB_STEP_DOC} from 'bl/ns/process/steps'

import ProcessFile    from './ProcessFile'
import processTaxCalc from './tax'
import Inspect from './flow/Inspect'
import Penalty20 from './flow/Penalty20'
import Penalty19 from './flow/Penalty19'
import { COLOR_PENALTY_20, COLOR_DISABLED, 
         COLOR_PENALTY_19, COLOR_PENALTY_19_BAD,
         COLOR_INSPECT_NEGATIVE, COLOR_INSPECT_TAX_NOT_READY,
         COLOR_INSPECT } from 'bl/ns/process/colors';
import {PENALTY_INFRACTION_LEVEL } from 'bl/ns/process/penalty'

class ProcessCalc {

  id                        = undefined
  edition_id                = undefined
  tax_code                  = ''
  
  
  name                      = ''
  process_type              = undefined
  year_from                 = undefined
  year_to                   = undefined
  appointment_date          = undefined
  
  
  tax_data                  = {}

  closing_reason            = undefined
  closing_explanation       = ''
  closed_at                 = undefined
  closed_by                 = undefined
  closed_by_name            = ''

  edition_name              = ''

  taxpayer_id               = undefined
  taxpayer_name             = ''
  taxpayer_address          = ''
  taxpayer_province         = ''
  taxpayer_nif              = ''
  taxpayer_sector_id        = undefined
  taxpayer_sector_name      = ''
  taxpayer_sector_code      = ''
  taxpayer_activity         = ''
  taxpayer_sector_activity  = ''

  taxpayer_name_custom      = ''
  taxpayer_sector_id_custom = undefined
  taxpayer_activity_custom  = ''

  
  ordinance_id              = undefined
  ordinance_name            = ''
  ordinance_title           = ''
  ordinance_tax_data        = {}
  
  council_id                = undefined
  council_name              = ''
  council_deh               = ''
  council_clerk_name        = ''
  council_clerk_pos         = ''
  council_major_genre       = GENRE_MALE
  council_inhabitants       = 0
  council_iban              = ''
  council_letterhead        = false
  
  agent_id                  = undefined
  agent_name                = ''
  agent_color               = undefined
  agent_phone               = ''
  
  first_dlg_satisfied       = undefined
  second_dlg_satisfied      = undefined
  third_dlg_satisfied       = undefined
  force_unatt_requirement   = undefined
  liq_proposal_arguments    = false
  minutes_agreed            = false
  minutes_agreed_date       = undefined
  minutes_serial            = undefined
  minutes_serial_year       = undefined
  minutes_arguments         = false
  minutes_arguments_date    = undefined
  informal_appeal           = false
  estimated                 = false
  judicial                  = false

  penalty_20_on             = undefined
  penalty_20_enabled        = undefined
  penalty_20_serial         = undefined
  penalty_20_agreed         = undefined
  penalty_20_agreed_date    = undefined
  penalty_20_arguments      = undefined
  penalty_20_arguments_date = undefined
  penalty_20_reduct_payment = true
  penalty_20_reduct_payment_value = 40
  penalty_20_remarks        = undefined
  penalty_20_revenues       = 0.0

  penalty_19_on             = undefined
  penalty_19_enabled        = undefined
  penalty_19_type           = undefined
  penalty_19_serial         = undefined
  penalty_19_agreed         = undefined
  penalty_19_agreed_date    = undefined
  penalty_19_arguments      = undefined
  penalty_19_arguments_date = undefined
  penalty_19_years          = undefined
  /*penalty_19_rank_rule      = undefined
  penalty_19_rank_rule_level= undefined
  penalty_19_rank_rule_loss = 0.0
  penalty_19_rank_rule_value= []*/
  penalty_19_reduct_agreed  = undefined
  penalty_19_reduct_payment = true
  penalty_19_reduct_payment_value = 40
  penalty_19_remarks        = ''

  paid_amount               = 0.0
  paid_fee                  = false
  paid_interests            = false
  paid_penalty_19           = false
  paid_penalty_20           = false

  invoice_disabled          = false
  invoice_number            = ''
  income_tags               = ''
  income_remarks            = ''

  historical                = false

  files                     = []

  notes                     = undefined // optional
  docus                     = undefined // optional
  irates                    = undefined // optional
  tax                       = undefined // optional

  created_at                = undefined
  last_update_at            = undefined
  created_by                = undefined
  last_update_by            = undefined
  undo                      = []

  constructor(obj) {
    if (typeof obj == 'object') {
      Object.keys(this)
            .filter((k) => Object.prototype.hasOwnProperty.call(obj, k))
            .filter((k) => ['tax', 'files'].indexOf(k)==-1)
            .map((k) => {
              this[k]= obj[k]
            })

      if (obj.tax!=undefined  /*&& Object.keys(obj.tax).length > 0*/) {
        this.tax = processTaxCalc(obj.tax, this)
      }
      this.files= obj.files.map((f) => new ProcessFile(f))
    }

    
    this.inspect= new Inspect(this)
    this.penalty_19= new Penalty19(this)
    this.penalty_20= new Penalty20(this)
  }

  @memoize
  get year_list () {
    let ys= []
    for (let y=this.year_from; y<this.year_to; y++) {
      ys.push(y)
    }
    return `${ys.join(', ')} y ${this.year_to}`
  }

  get ordinance_inspection_date() {
    return this.ordinance_tax_data!=undefined
           ? this.ordinance_tax_data.inspection_date
           : undefined
  }

  @memoize
  get start_date() {
    try {
      return this.inspect.start.sub[0].doc.file.notification_date
    } catch(e) {}
    return undefined
  }

  @memoize
  get end_date() {
    if (this.is_closed) {
      return this.closed_at
    }
    try {
      return this.inspect.payment.sub[0].doc.file.last_print_at
    } catch(e) {}
    return undefined
  }  

  @memoize
  get start_accepted() {
    try {
      return this.inspect.payment.sub[0].doc.file.notification_accepted
    } catch(e) {}
    return undefined
  }  

  @memoize
  get end_liq_date() {
    if (this.process_type == PTYPE.COMPLETE) {
      if (this.appointment_date) {
        const one_month= intre_add_months(this.appointment_date, 1)
        return intre_add_days(one_month, 1)
      }
    } else {
      // NOTE Only for I5, IAE
      if (this.tax_data != undefined) {
        return this.tax_data.custom_end_liq_date
      }
      
    }
    return undefined
  }

  @memoize
  get liq_proposal_date() {
    try {
      return this.inspect.liq_proposal.sub[1].doc.file.notification_date
    } catch(e) {}
    return undefined
  }

  @memoize
  get second_req_date() {
    try {
      return this.inspect.second_req.sub[0].doc.file.notification_date
    } catch(e) {}
    return undefined
  }

  @memoize
  get second_req_accepted() {
    try {
      return this.inspect.second_req.sub[0].doc.file.notification_accepted
    } catch(e) {}
    return undefined
  }  

  @memoize
  get third_req_date() {
    try {
      return this.inspect.third_req.sub[0].doc.file.notification_date
    } catch(e) {}
    return undefined
  }


  @memoize
  get third_req_accepted() {
    try {
      return this.inspect.third_req.sub[0].doc.file.notification_accepted
    } catch(e) {}
    return undefined
  }  
  
  @memoize
  get doc_provided_date() {
    if (this.third_dlg_satisfied==1) 
    {
      try {
        const d= this.inspect.third_dlg.sub[1].doc.file.doc_date
        if (d!=undefined) {
          return d
        }
      } catch(e) {}
    }

    if (this.second_dlg_satisfied==1) 
    {
      try {
        const d= this.inspect.second_dlg.sub[1].doc.file.doc_date
        if (d!=undefined) {
          return d
        }
      } catch(e) {}
    }

    if (this.first_dlg_satisfied==1) 
    {
      try {
        const d= this.inspect.first_dlg.sub[1].doc.file.doc_date
        if (d!=undefined) {
          return d
        }
      } catch(e) {}  
    }
    return undefined
  }

  @memoize
  get minutes_notification_date() {
    try {
      return this.inspect.minutes.sub[2].doc.file.notification_date
    } catch(e) {}
    return undefined
  }

  /*
  @memoize
  get closed() {
    return {
      id      : STEP_CLOSED,
      name    : 'Cierre Sin Acta',
      progress: { color: '#000000', len: 100},
      done    : this.closing_reason!=undefined
    }    
  }
  */

  get is_closed() {
   return this.closing_reason!=undefined
  }

  @memoize
  get amounts_real() {
    const _uvl = (n) => ((n==undefined) || (isNaN(n))) ? 0.0 : parseFloat(n)

    return {
      fees:       _uvl(this?.tax?.fees),
      interests:  _uvl(this?.tax?.interests),
      inspect:    _uvl(this?.tax?.total),
      penalty_20: _uvl(this?.penalty_20?.total),
      penalty_19: _uvl(this?.penalty_19?.amounts_sum?.total)
    }
  }

  @memoize
  get amounts() {
    const p20_ok= this.penalty_20_on && (this.penalty_20_enabled!==false)
    const p19_ok= this.penalty_19_on && (this.penalty_19_enabled!==false)

    return {
      fees:       this.is_closed ? 0.0 : this.amounts_real.fees,
      interests:  this.is_closed ? 0.0 : this.amounts_real.interests,
      inspect:    this.is_closed ? 0.0 : this.amounts_real.inspect,
      penalty_20: p20_ok ? this.amounts_real.penalty_20 : 0.0,
      penalty_19: p19_ok ? this.amounts_real.penalty_19 : 0.0,
    }
  }

  @memoize
  get amount_colors() {
    let inspect= COLOR_INSPECT
    if (this.is_closed || (this.amounts.inspect>-1 && this.amounts.inspect<1)) {
      inspect= COLOR_DISABLED
    } else if (! this.tax.ready) {
      inspect= COLOR_INSPECT_TAX_NOT_READY
    } else if (this.amounts.inspect<0) {
      return COLOR_INSPECT_NEGATIVE
    }
    
    let penalty_20 = COLOR_PENALTY_20
    if ((! this.penalty_20_on) || (this.penalty_20_enabled===false)) {
      penalty_20 = COLOR_DISABLED
    }
    
    let penalty_19 = COLOR_PENALTY_19

    if ((! this.penalty_19_on) || (this.penalty_19_enabled===false)) {
      penalty_19 = COLOR_DISABLED
    }
    else if (this.penalty_19.infraction_level==PENALTY_INFRACTION_LEVEL.BAD) {
      penalty_19 = COLOR_PENALTY_19_BAD
    }
    
    return {
      inspect,
      penalty_19,
      penalty_20
    }
  }


  @memoize
  get has_penalty() {
    return this.penalty_20_on===true || this.penalty_19_on===true
  }


  @memoize
  get is_done() {
    if (this.is_closed) {
      return true
    }     
    if (this.inspect.is_done) {
      if ( (this.penalty_20_on!==true || (this.penalty_20_on===true && this.penalty_20.is_done)) &&
           (this.penalty_19_on!==true || (this.penalty_19_on===true && this.penalty_19.is_done))) {
        return true
      }
    }
    return false
  }

  @memoize
  get invoicable() {
    if (this.is_closed) {
      return false
    }
    return this.is_done
  }

  @memoize
  get to_be_paid() {
    if (! this.invoicable) {
      return 0.0
    }
    return this.amounts.fees + this.amounts.interests + this.amounts.penalty_19 + this.amounts.penalty_20
  }

  @memoize
  get is_paid() {
    const to_be_paid = Math.round(this.to_be_paid, 2)
    const paid_amount = Math.round(this.paid_amount || 0.0, 2)

    return paid_amount >= to_be_paid
  }  


  @memoize
  get progress() {
    let tot= this.inspect.progress
    if (this.penalty_20_on===true) {
      tot+= this.penalty_20.pogress
    }
    if (this.penalty_19_on===true) {
      tot+= this.penalty_19.pogress
    }
    return tot*100/300
  }

  get activity() {
    return this.taxpayer_activity_custom
      || this.taxpayer_activity
      || this.taxpayer_sector_activity
      || ''
  }

  filterDocVersionsForStep(docs, step, docId) {
    const nDocList= []

    docs.map((v) => {
      if (v.process_doc==docId) {

        let vTaxCode= undefined
        let vProcType= undefined
        let vPenaltyType = undefined
        let vPenaltyInfraction = undefined
        let vPenaltyAgreed = undefined
        let vUnattRequire = undefined
        let vCouncilId = undefined
        let vSectorId = undefined

        let nConditions= 0

        if (v.conditions!=undefined) {
          vTaxCode= v.conditions.tax_code
          vProcType= v.conditions.process_type
          vPenaltyType = v.conditions.penalty_type
          vPenaltyInfraction = v.conditions.penalty_infraction    
          vPenaltyAgreed = v.conditions.penalty_agreed
          vUnattRequire= v.conditions.unatt_requirement
          vCouncilId= v.conditions.council_id
          vSectorId= v.conditions.sector_id

          Object.entries(v.conditions).map((k,v) => {
            if (v!=undefined) {
              nConditions+= 1
            }
          })
        }

        const matchTaxCode= vTaxCode==undefined || vTaxCode==this.tax_code
        const matchProcType= vProcType==undefined || vProcType==this.process_type
        const matchPenaltyType= vPenaltyType==undefined || vPenaltyType.length==0 || (vPenaltyType.indexOf(step.penalty_type)>=0)
        const matchPenaltyInfraction= vPenaltyInfraction==undefined 
                                      || this.penalty_19_on!==true 
                                      || vPenaltyInfraction==this.penalty_19.infraction_level
        const matchPenaltyAgreed= vPenaltyAgreed==undefined 
                                  || vPenaltyAgreed===uvl(this.minutes_agreed, true)
        
        const matchUnattReq= vUnattRequire==undefined || vUnattRequire==this.inspect.unatt_requirement
        const matchCouncil = vCouncilId==undefined || vCouncilId == this.council_id
        const matchSector = vSectorId==undefined || vSectorId == this.taxpayer_sector_id


        if (matchTaxCode && matchProcType && matchPenaltyType 
            && matchPenaltyInfraction && matchPenaltyAgreed 
            && matchUnattReq && matchCouncil && matchSector) {
          nDocList.push({id: v.id, name: v.name, required_fields: v.required_fields, n_conditions: nConditions})
        }
      }
    })
    return collSort(nDocList, 'n_conditions', 'desc')
  }

  filterDocVersionsUntilStep(docs, flow) {
    let allDocs= []
    if (flow.steps) {
      for (let i = flow.steps.length-1; i >= 0; i--) {
        const step= flow.steps[i]
      
        for (let j=step.sub.length-1; j >= 0; j--) {
          if (step.sub[j].type==SUB_STEP_DOC) {
            const subStep= step.sub[j]
            const docId= subStep.doc.id
            this.filterDocVersionsForStep(docs, subStep, docId)
                 .map((d) => allDocs.push(d))
          }
        }

      }
    }
    return allDocs
  }



  forDocx(processStep, processDoc) {
    
    const _isOf = (coll) => Object.values(coll).indexOf(processStep)>=0
    const _getSubStepAndDoc = (steps) => {
      let subStep= undefined, subDoc= undefined
      steps.map((step) => {
        if (subStep===undefined) {
          if (step.id==processStep) {
            step.sub.map((su) => {
              if (su.type==SUB_STEP_DOC) {
                if (su.doc!=undefined) {
                  if (su.doc.id==processDoc) {
                    subStep= su
                    subDoc= su.doc
                  } else {
                    if (su.extra_docs!=undefined) {
                      su.extra_docs.map((d) => {
                        if (d.id==processDoc) {
                          subStep= su
                          subDoc= d
                        }
                      })
                    }
                  }
                }
              }
            })
          }
        }
      })
      return [subStep, subDoc]
    }

    const sourceSteps= _isOf(FLOW_STEPS)
      ? this.inspect.steps
      : _isOf(PENALTY_20_STEPS)
        ? this.penalty_20.steps
        : this.penalty_19.steps
    
    const [subStep, subDoc]= _getSubStepAndDoc(sourceSteps)

    if (subDoc==undefined) {
      console.log('HEY UNDEFINED')
      console.log(this.edition_id)
      console.log(this.id)
    }

    return {
      process: {
        id                        : this.id,
        name                      : this.name               ,
        tax_code                  : this.tax_code           ,
        appointment_date          : this.appointment_date   ,
        end_liq_date              : this.end_liq_date       , 
        start_date                : this.start_date         ,
        start_accepted            : this.start_accepted     ,
        year_from                 : this.year_from          ,
        year_to                   : this.year_to            ,
        year_list                 : this.year_list          ,
        liq_proposal_date         : this.liq_proposal_date  ,
        liq_proposal_arguments    : this.liq_proposal_arguments,
        first_dlg_satisfied       : this.first_dlg_satisfied,
        second_dlg_satisfied      : this.second_dlg_satisfied,
        third_dlg_satisfied       : this.third_dlg_satisfied,
        second_req_date           : this.second_req_date    ,
        second_req_accepted       : this.second_req_accepted,
        third_req_date            : this.third_req_date     ,
        third_req_accepted        : this.third_req_accepted ,
        minutes_serial            : this.inspect.serial_text,
        minutes_arguments         : this.minutes_arguments  ,
        minutes_arguments_date    : this.minutes_arguments_date  ,
        minutes_agreed            : this.minutes_agreed,
        minutes_agreed_date       : this.minutes_agreed_date,
        minutes_notification_date : this.minutes_notification_date,
        unatt_requirement         : this.inspect.unatt_requirement,
        doc_provided_date         : this.doc_provided_date,
        amounts                   : this.amounts,
      },
      penalty_20: {
        on                        : this.penalty_20_on===true,
        enabled                   : this.penalty_20_enabled===true,
        start_notif_date          : this.penalty_20.start_notif_date,
        start_notif_accepted      : this.penalty_20.start_notif_accepted,
        serial                    : this.penalty_20_serial  ,
        arguments                 : this.penalty_20_arguments,
        arguments_date            : this.penalty_20_arguments_date,
        agreed_date               : this.penalty_20_agreed_date,
        reduct_payment            : uvl(this.penalty_20_reduct_payment,true),
        revenues                  : this.penalty_20_revenues,
        raw_amount                : this.penalty_20.raw_amount,
        discounted                : this.penalty_20.discounted,
        reducted                  : this.penalty_20.reducted,
        total                     : this.penalty_20.total
      },
      penalty_19: {
        on                        : this.penalty_19_on===true,
        enabled                   : this.penalty_19_enabled===true,
        serial                    : this.penalty_19_serial  ,
        start_notif_date          : this.penalty_19.start_notif_date,
        start_notif_accepted      : this.penalty_19.start_notif_accepted,
        arguments                 : this.penalty_19_arguments,
        arguments_date            : this.penalty_19_arguments_date,
        agreed_date               : this.penalty_19_agreed_date,
        agreed                    : uvl(this.penalty_19_agreed,true),
        applies_agree_discount    : this.penalty_19.applies_agree_discount,
        infraction_level          : this.penalty_19.infraction_level,
        reduct_payment            : uvl(this.penalty_19_reduct_payment,true),
        difference                : this.penalty_19.amounts_sum.difference,
        fee                       : this.penalty_19.amounts_sum.base,
        after_discount            : this.penalty_19.amounts_sum.after_discount,
        after_agreed              : this.penalty_19.amounts_sum.after_agreed,
        red_by_discount           : this.penalty_19.amounts_sum.red_by_discount,
        red_by_agreed             : this.penalty_19.amounts_sum.red_by_agreed,
        red_by_payment            : this.penalty_19.amounts_sum.red_by_payment,
        total                     : this.penalty_19.amounts_sum.total,
        detail                    : this.penalty_19.amounts
      },
      tax                         : this.tax!=undefined ? this.tax.forDocx() : {},
      step: {
        penalty_type              : subStep?.penalty_type    ,
      },
      doc: {
        id                        : subDoc.id,
        doc_date                  : subDoc.file.doc_date    ,
        agent_name                : subDoc.file.agent_name  ,
        agent_phone               : subDoc.file.agent_phone ,
        signer_name               : subDoc.file.signer_name ,
        signer_nif                : subDoc.file.signer_nif  ,
        signer_pos                : subDoc.file.signer_pos  
      },
      council: {
        name                      : this.council_name       ,
        deh                       : this.council_deh        ,
        clerk_name                : this.council_clerk_name ,
        clerk_pos                 : this.council_clerk_pos  ,
        major_genre               : this.council_major_genre || GENRE_MALE,
        iban                      : this.council_iban       ,
      },
      taxpayer: {
        name                      : this.taxpayer_name      ,
        nif                       : this.taxpayer_nif       ,
        address                   : this.taxpayer_address   ,
        province                  : SPANISH_PROVINCES[this.taxpayer_province] || '',
        sector_id                 : this.taxpayer_sector_id,
        sector_name               : this.taxpayer_sector_name,
        sector_code               : this.taxpayer_sector_code,
        activity                  : this.activity,
      },
      ordinance: {
        title                     : this.ordinance_title || this.ordinance_name,
        liq_limit_day             : this.ordinance_tax_data!=undefined ? this.ordinance_tax_data.liq_limit_day : undefined,
        liq_limit_month           : this.ordinance_tax_data!=undefined ? this.ordinance_tax_data.liq_limit_month : undefined,
        inspection_date           : this.ordinance_tax_data!=undefined ? this.ordinance_tax_data.inspection_date : undefined,
      },
      agent: {
        name                      : this.agent_name,
        phone                     : this.agent_phone
      }
    }
  }

  toJson() {
    let json= {
      id                            : this.id                           ,
      edition_id                    : this.edition_id                   ,
      taxpayer_id                   : this.taxpayer_id                  ,
      tax_code                      : this.tax_code                     ,
      name                          : this.name                         ,
      process_type                  : this.process_type                 ,
      year_from                     : this.year_from                    ,
      year_to                       : this.year_to                      ,
      appointment_date              : this.appointment_date             ,
      tax_data                      : this.tax_data || {}               ,
      closing_reason                : this.closing_reason               ,
      closing_explanation           : this.closing_explanation          ,
      closed_at                     : this.closed_at                    ,
      closed_by                     : this.closed_by                    ,
      edition_name                  : this.edition_name                 ,
      taxpayer_name                 : this.taxpayer_name                ,
      taxpayer_address              : this.taxpayer_address             ,
      taxpayer_province             : this.taxpayer_province            ,
      taxpayer_nif                  : this.taxpayer_nif                 ,
      taxpayer_sector_id            : this.taxpayer_sector_id           ,
      taxpayer_sector_name          : this.taxpayer_sector_name         ,
      taxpayer_sector_code          : this.taxpayer_sector_code         ,
      taxpayer_activity             : this.taxpayer_activity            ,
      taxpayer_sector_activity      : this.taxpayer_sector_activity     ,
      taxpayer_name_custom          : this.taxpayer_name_custom         ,
      taxpayer_sector_id_custom     : this.taxpayer_sector_id_custom    ,
      taxpayer_activity_custom      : this.taxpayer_activity_custom     ,
      ordinance_id                  : this.ordinance_id                 ,
      ordinance_name                : this.ordinance_name               ,
      ordinance_title               : this.ordinance_title              ,
      ordinance_tax_data            : this.ordinance_tax_data           ,
      council_id                    : this.council_id                   ,
      council_name                  : this.council_name                 ,
      council_deh                   : this.council_deh                  ,
      council_clerk_name            : this.council_clerk_name           ,
      council_clerk_pos             : this.council_clerk_pos            ,
      council_major_genre           : this.council_major_genre          ,
      council_inhabitants           : this.council_inhabitants          ,
      council_iban                  : this.council_iban                 ,
      council_letterhead            : this.council_letterhead           ,
      agent_id                      : this.agent_id                     ,
      agent_name                    : this.agent_name                   ,
      agent_color                   : this.agent_color                  ,
      agent_phone                   : this.agent_phone                  ,
      first_dlg_satisfied           : this.first_dlg_satisfied          ,
      second_dlg_satisfied          : this.second_dlg_satisfied         ,
      third_dlg_satisfied           : this.third_dlg_satisfied          ,
      force_unatt_requirement       : this.force_unatt_requirement      ,
      liq_proposal_arguments        : this.liq_proposal_arguments       ,
      minutes_agreed                : this.minutes_agreed               ,
      minutes_agreed_date           : this.minutes_agreed_date          ,
      minutes_serial                : this.minutes_serial               ,
      minutes_serial_year           : this.minutes_serial_year          ,
      minutes_arguments             : this.minutes_arguments            ,
      minutes_arguments_date        : this.minutes_arguments_date       ,
      informal_appeal               : this.informal_appeal              ,
      estimated                     : this.estimated                    , 
      judicial                      : this.judicial                     ,

      penalty_20_on                 : this.penalty_20_on                ,
      penalty_20_enabled            : this.penalty_20_enabled           ,
      penalty_20_serial             : this.penalty_20_serial            ,
      penalty_20_agreed             : this.penalty_20_agreed            ,
      penalty_20_agreed_date        : this.penalty_20_agreed_date       ,
      penalty_20_arguments          : this.penalty_20_arguments         ,
      penalty_20_arguments_date     : this.penalty_20_arguments_date    ,
      penalty_20_reduct_payment     : this.penalty_20_reduct_payment    ,
      penalty_20_reduct_payment_value     : this.penalty_20_reduct_payment_value    ,
      penalty_20_remarks            : this.penalty_20_remarks           ,
      penalty_20_revenues           : this.penalty_20_revenues          ,

      penalty_19_on                 : this.penalty_19_on                ,
      penalty_19_enabled            : this.penalty_19_enabled           ,
      penalty_19_type               : this.penalty_19_type              ,
      penalty_19_serial             : this.penalty_19_serial            ,
      penalty_19_agreed             : this.penalty_19_agreed            ,
      penalty_19_agreed_date        : this.penalty_19_agreed_date       ,
      penalty_19_arguments          : this.penalty_19_arguments         ,
      penalty_19_arguments_date     : this.penalty_19_arguments_date    ,
      penalty_19_years              : this.penalty_19_years             ,
      /*penalty_19_rank_rule          : this.penalty_19_rank_rule         ,
      penalty_19_rank_rule_level    : this.penalty_19_rank_rule_level   ,
      penalty_19_rank_rule_loss     : this.penalty_19_rank_rule_loss    ,
      penalty_19_rank_rule_value    : this.penalty_19_rank_rule_value   ,*/
      penalty_19_reduct_agreed       : this.penalty_19_reduct_agreed,
      penalty_19_reduct_payment     : this.penalty_19_reduct_payment    ,
      penalty_19_reduct_payment_value     : this.penalty_19_reduct_payment_value    ,
      penalty_19_remarks            : this.penalty_19_remarks           ,

      paid_amount                   : this.paid_amount                  ,
      paid_fee                      : this.paid_fee                     ,
      paid_interests                : this.paid_interests               ,
      paid_penalty_19               : this.paid_penalty_19              ,
      paid_penalty_20               : this.paid_penalty_20              ,
      invoice_disabled              : this.invoice_disabled             ,
      invoice_number                : this.invoice_number               ,
      income_tags                   : this.income_tags                  ,
      income_remarks                : this.income_remarks               ,
      historical                    : this.historical,

      files                         : this.files.map((f) => f.toJson()),

      created_at                    : this.created_at                   ,
      last_update_at                : this.last_update_at               ,
      created_by                    : this.created_by                   ,
      last_update_by                : this.last_update_by               ,
      undo                          : [...this.undo]                    ,
      amounts_real                  : this.amounts_real,
      amounts                       : this.amounts,
      amount_colors                 : this.amount_colors,

    }  

    if (this.irates!=undefined) {
      json.irates= [... this.irates]
    }
    if (this.notes!=undefined) {
      json.notes= [... this.notes]
    }
    if (this.docus!=undefined) {
      json.docus= [... this.docus]
    }
    if (this.tax!=undefined) {
      json.tax= this.tax.toJson()
    }    
    return json        

  }

  export() {
    return {
      ...this.toJson(),

      end_liq_date    : this.end_liq_date,
      
      is_closed       : this.is_closed,
      has_penalty     : this.has_penalty,

      is_done         : this.is_done,
      invoicable      : this.invoicable,
      to_be_paid      : this.to_be_paid,
      is_paid         : this.is_paid,

      inspect         : this.inspect.export(),
      penalty_19      : this.penalty_19.export(),
      penalty_20      : this.penalty_20.export(),

      tax             : this.tax!=undefined ? this.tax.export() : undefined,
      closed_by_name  : this.closed_by_name
    }
  }

}

export default ProcessCalc
