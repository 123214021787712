import React from 'react'
import { render } from 'react-dom'
import App from 'cli/App'

// Import styles for this application
import 'cli/static/scss/style.scss'

render(
  <App/>,
  document.getElementById('root')
  )

if (module.hot) {
  module.hot.accept();
}
